/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    strong: "strong",
    br: "br",
    ul: "ul",
    li: "li",
    h3: "h3",
    ol: "ol",
    a: "a",
    h4: "h4",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {ContentFilters, Admonition, FilteredContent, RequestParameters, RequestSample, ResponseBody, ResponseSample} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!ContentFilters) _missingMdxReference("ContentFilters", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!RequestParameters) _missingMdxReference("RequestParameters", true);
  if (!RequestSample) _missingMdxReference("RequestSample", true);
  if (!ResponseBody) _missingMdxReference("ResponseBody", true);
  if (!ResponseSample) _missingMdxReference("ResponseSample", true);
  return React.createElement(React.Fragment, null, React.createElement(ContentFilters, {
    filters: ["Request parameters", "Request samples", "Response body", "Response sample"]
  }), "\n", React.createElement(_components.p, null, "Allow your merchants to accept Apple Pay as a payment method."), "\n", React.createElement(_components.h2, null, "Integration steps"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 1."), " Integrate with the Apple Pay JS API.", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 2."), " Integrate with the Payroc API:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "2a. Start an Apple Pay session."), "\n", React.createElement(_components.li, null, "2b. Run a sale."), "\n"), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.h3, null, "Bearer tokens"), "\n", React.createElement(_components.p, null, "Use our Identity Service to generate a Bearer token to include in the header of your requests. To generate your Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h4, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n"))), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h4, null, "Example response"), React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\"access_token\": \"eyJhbGc....adQssw5c\",\n\"expires_in\": 3600,\n\"scope\": \"service_a service_b\",\n\"token_type\": \"Bearer\"\n}\n"))), "\n", React.createElement(_components.h3, null, "Headers"), "\n", React.createElement(_components.p, null, "To create the header of each GET request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n"))), "\n", React.createElement(_components.p, null, "To create the header of each POST request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Idempotency-Key:"), " Include a UUID v4 to make the request idempotent."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n-H \"Idempotency-Key: <UUID v4>\"\n"))), "\n", React.createElement(_components.h3, null, "Errors"), "\n", React.createElement(_components.p, null, "If your request is unsuccessful, we return an error. For more information about errors, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/errors"
  }, "Errors"), "."), "\n", React.createElement(_components.h2, null, "Step 1. Integrate with the Apple JS API"), "\n", React.createElement(_components.p, null, "To integrate with the Apple Pay JS API, go to ", React.createElement(_components.a, {
    href: "https://developer.apple.com/apple-pay/"
  }, "https://developer.apple.com/apple-pay/"), "."), "\n", React.createElement(_components.p, null, "Your integration with Apple must retrieve the following information:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Validation URL"), " - Apple provides the validation URL that you send to us when you create an Apple Pay session."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Encrypted payment details"), " - Apple encrypts the cardholder's payment details and returns them to your integration. After you receive the encrypted payment details, convert them to hexidecimal."), "\n"), "\n", React.createElement(_components.h2, null, "Step 2. Integrate with the Payroc API"), "\n", React.createElement(_components.p, null, "Use our API to start the merchant session with Apple Pay and retrieve the startSessionResponse object. After you use the Apple Pay JS API to encrypt the cardholder's payment details, use our API to run the sale."), "\n", React.createElement(_components.h3, null, "Step 2a. Start an Apple Pay session"), "\n", React.createElement(_components.p, null, "To start an Apple Pay session with Apple, send a request to our Apple Pay sessions endpoint."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/processing-terminals/%7BprocessingTerminalId%7D/apple-pay-sessions"
  }, "https://api.uat.payroc.com/v1/processing-terminals/{processingTerminalId}/apple-pay-sessions"), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/processing-terminals/%7BprocessingTerminalId%7D/apple-pay-sessions"
  }, "https://api.payroc.com/v1/processing-terminals/{processingTerminalId}/apple-pay-sessions")), "\n", React.createElement(_components.p, null, "In the body of your request, include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "appleDomainId"), " - You can view the appleDomainId after you add the merchant's domain to the Self-Care Portal."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "appleValidationUrl"), " - Apple provides the validation URL as part of your integration with the Apple Pay JS API."), "\n"), "\n", React.createElement(_components.p, null, "In the response, we return the startSessionResponse object. Send the startSessionResponse object to Apple to retrieve the cardholder's encrypted payment details."), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "applePaySessions"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "applePaySessions"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, our gateway starts the merchant session with Apple and returns the following fields:"), React.createElement(ResponseBody, {
    operationId: "applePaySessions"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "applePaySessions"
  })), "\n", React.createElement(_components.h3, null, "Step 2b. Run a sale"), "\n", React.createElement(_components.p, null, "After you retrieve the cardholder's encrypted payment details from Apple, use our payments endpoint to run a sale."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/payments"
  }, "https://api.uat.payroc.com/v1/payments"), "\n", React.createElement(_components.strong, null, "Production endpoint:"), " ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/payments"
  }, "https://api.payroc.com/v1/payments")), "\n", React.createElement(_components.p, null, "In your request, send the following parameters in the paymentMethod object:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "type"), " - Provide a value of ", React.createElement(_components.code, null, "digitalWallet"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "serviceProvider"), " - Provide a value of ", React.createElement(_components.code, null, "apple"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "encryptedData"), " - Provide the encrypted payment details that you retrieved from the Apple Pay JS API. The payment details must be in hexadecimal format."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(_components.h3, null, "Request parameters"), React.createElement(_components.p, null, "To create the body of your request, use the following parameters:"), React.createElement(RequestParameters, {
    operationId: "payment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "payment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(_components.h3, null, "Response fields"), React.createElement(_components.p, null, "If your request is successful, our gateway uses the card details to run a sale. The response contains the following fields:"), React.createElement(ResponseBody, {
    operationId: "payment"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h3, null, "Example response"), React.createElement(ResponseSample, {
    operationId: "payment"
  })), "\n", React.createElement(_components.h2, null, "Next steps"), "\n", React.createElement(_components.p, null, "After you integrate with the Apple Pay JS API and the Payroc API, complete the following steps:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Set up Apple Pay for each merchant"), " - To set up Apple Pay for a merchant to run transactions, go to ", React.createElement(_components.a, {
    href: "/guides/integrate/set-up-apple-pay-merchant"
  }, "Set up Apple Pay for a Merchant"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Set up a Sandbox Apple Account"), " - To run test transactions with Apple Pay, set up a Sandbox Apple Account. To set up a Sandbox Apple Account, contact our Integrations Team at ", React.createElement(_components.a, {
    href: "mailto:integrationsupport@payroc.com"
  }, "integrationsupport@payroc.com"), "."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
